import { UnsignedStatsDto, FacilityQuickNotesDto, UnacknowledgedQuickNotesBadgeDto, CensusBadgeDto } from '@medone/medonehp-api-client';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { PrintState } from './unsigned-notes/print';

export const quickNotesBadgesAdapter = createEntityAdapter<CensusBadgeDto, number>({
    selectId: (item) => item.patientIntakeId,
});

export interface PostAcuteState {
    errorMessage: string | null;
    totalUnsignedNotes: UnsignedStatsDto;
    totalUnacknowledgedQuickNotes: UnacknowledgedQuickNotesBadgeDto;
    totalDraftQuickNotes: number;
    unacknowledgedQuickNotes: FacilityQuickNotesDto[];
    totalReturnToHospital: number;
    quickNotesBadges: EntityState<CensusBadgeDto, number>;
    printState: PrintState;
}

export const initialState: PostAcuteState = {
    errorMessage: null,
    totalUnsignedNotes: null,
    totalUnacknowledgedQuickNotes: null,
    totalDraftQuickNotes: null,
    unacknowledgedQuickNotes: [],
    totalReturnToHospital: null,
    quickNotesBadges: quickNotesBadgesAdapter.getInitialState({}),
    printState: null,
};

export interface FacilityQuickNotesCountUpdatedDto {
    admittedToId: number;
    quickNotesNeedsFollowupCount: number;
    quickNotesUnacknowledgedCount: number;
}

export interface ProviderQuickNotesCountUpdatedDto {
    adSid: string;
    drafts: UnacknowledgedQuickNotesBadgeDto;
    nonDrafts: UnacknowledgedQuickNotesBadgeDto;
}

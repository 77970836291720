import axios from 'axios';
import axiosThrottle from 'axios-request-throttle';

import { store } from '../../store';
import { isBedBoardIntakeForm } from '../helpers';
import { getCachedToken } from '../auth/slice';

export const Axios: any = axios.create();

axiosThrottle.use(Axios, { requestsPerSecond: 5 });

interface Failure {
    api: string;
    count: number;
}

let failures: Failure[] = [];

Axios.interceptors.request.use(
    async (config) => {
        const state = store.getState();
        const account = state.auth?.account;

        const url = new URL(config.url);

        if (!isBedBoardIntakeForm() && account && (url.hostname === 'localhost' || url.hostname === '127.0.0.1' || url.hostname.endsWith('medonehp.com'))) {
            const token = await store.dispatch(getCachedToken())
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
    (response) => {
        const index = failures.findIndex((x) => x.api === response.config.url);

        if (index >= 0) {
            failures.splice(index, 1);
        }

        return response;
    },

    async (error) => {
        // Don't show cancel token results here
        if (error?.message != null && error.message.includes('Operation canceled')) {
            return Promise.resolve();
        }

        if (error?.config?.url === '/health') {
            return Promise.reject(error);
        }

        if (error?.response != null) {
            if (error.response?.status === 401 || error.response?.status === 403) {
                const index = failures.findIndex((x) => x.api === error.config.url);

                if (index >= 0) {
                    if (failures[index].count > 3) {
                        window.location.href = '/noaccess';

                        return Promise.resolve();
                    } else {
                        const newFailures = [...failures];

                        newFailures.splice(index, 1);
                        newFailures.push({ ...failures[index], count: failures[index].count + 1 });

                        failures = newFailures;
                    }
                } else {
                    failures.push({ api: error.config.url, count: 1 });
                }

                const token = await store.dispatch(getCachedToken());
                error.config.headers['Authorization'] = `Bearer ${token}`;

                return Axios.request(error.config);
            }

            // Don't show concurrency errors to the user
            try {
                if (
                    error.response.data != null &&
                    error.response?.status === 500 &&
                    typeof error.response?.data === 'string' &&
                    error.response?.data?.includes('DbUpdateConcurrencyException')
                ) {
                    return Promise.reject(`The data you're trying to save has changed since you loaded it. You may have to reload or try again.`);
                }
            } catch {}
        }

        return Promise.reject(error);
    }
);

import './shared/common/styles/antd/global.less';
import './shared/common/styles/global.scss';
import 'react-toastify/dist/ReactToastify.css';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { MsalProvider } from '@azure/msal-react';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { ConfigProvider } from 'antd';

import { SessionId } from './shared/common';
import { store } from './shared/store';
import { msalConfig } from './shared/common/auth/authConfig';

import { BuildInfo, VersionInfo } from './Config';
import { isBedBoardIntakeForm, isBeta } from './shared/common/helpers';
import { handleError } from './shared/common/HandleErrors';
import { getCachedToken } from './shared/common/auth/slice';

let dsn = 'https://feb95ac2d7bc487bb9412eb818fad87c@o428177.ingest.sentry.io/5377331';
let release = `portal@${VersionInfo.AppVersion}`;

if (BuildInfo.IsBedBoard) {
    dsn = 'https://0d0ada0b6a57450686983aa3e05b821e@o428177.ingest.sentry.io/4504713309847552';
    release = `bedboard@${VersionInfo.AppVersion}`;
}

Sentry.registerSpanErrorInstrumentation();

const initSentry = () => {
    Sentry.init({
        environment: window.location.hostname,
        dsn: dsn,
        release: release,
        tracesSampleRate: 1,
        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // results in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: 1,
        ignoreErrors: ['BrowserAuthError'],
        normalizeDepth: 5,

        // Tracing headers are only attached to requests that contain localhost, medonehp.com
        // or requests whose URL starts with a '/'(for example GET /api/data)
        tracePropagationTargets: ['localhost', 'medonehp.com', /^\//],

        integrations: [
            Sentry.browserTracingIntegration({
                enableInp: true,
            }),

            Sentry.browserProfilingIntegration(),
        ],

        tracesSampler: () => {
            return isBeta() ? 1 : 0.035;
        },

        beforeBreadcrumb(breadcrumb, _hint) {
            // Remove version check requests from the breadcrumb
            return breadcrumb.category === 'fetch' && breadcrumb.message?.includes('version?code=') ? null : breadcrumb;
        },

        beforeSend(event, hint) {
            const message = (hint.originalException ?? '').toString();

            if (
                message &&
                (RegExp(/Cannot read properties of undefined (reading 'status')/i).exec(message) ||
                    RegExp(/Loading chunk/i).exec(message) ||
                    RegExp(/undefined is not an object \(evaluating 'e.status'\)/i).exec(message) ||
                    RegExp(/Loading CSS chunk/i).exec(message) ||
                    RegExp(/BrowserAuthError/i).exec(message) ||
                    RegExp(/ClientAuthError/i).exec(message) ||
                    RegExp(/Network Error/i).exec(message))
            ) {
                return null;
            }

            return event;
        },
    });

    // https://docs.sentry.io/enriching-error-data/error-tracing/#parse-the-transaction-header
    Sentry.getCurrentScope().setTag('sessionId', SessionId);
};

export const msalInstance = new PublicClientApplication(msalConfig);

function importBuildTarget() {
    if (BuildInfo.IsBedBoard) {
        return isBedBoardIntakeForm() ? import('./bedboard/App-NoAuth') : import('./bedboard/App');
    }

    return import('./post-acute/App');
}

if (window.location?.search?.includes('version')) {
    console.log('VersionInfo:');
    console.log(VersionInfo);
}

const domNode = document.getElementById('root')!;
const root = createRoot(domNode);

importBuildTarget().then(({ default: TargetApp }) => {
    initSentry();

    // For noauth area, don't apply the msal component
    if (TargetApp.displayName.includes('AppNoAuth')) {
        root.render(
            <Provider store={store}>
                <ConfigProvider getPopupContainer={() => document.getElementById('popup-containers')}>
                    <TargetApp />
                </ConfigProvider>
            </Provider>
        );
    } else {
        msalInstance
            .initialize()
            .then(() => {
                const accounts = msalInstance.getAllAccounts();

                if (accounts.length > 0) {
                    msalInstance.setActiveAccount(accounts[accounts.length - 1]);
                }

                // Optional - This will update account state if a user signs in from another tab or window
                msalInstance.enableAccountStorageEvents();

                msalInstance.addEventCallback((event: EventMessage) => {
                    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                        const payload = event.payload as AuthenticationResult;
                        const account = payload.account;

                        msalInstance.setActiveAccount(account);

                        Sentry.setUser({ email: account.username });
                    } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                        store.dispatch(getCachedToken());
                    } else {
                        // Log the event(s)
                        //console.log('Login Event:', event);
                    }
                });

                root.render(
                    <Provider store={store}>
                        <MsalProvider instance={msalInstance}>
                            <ConfigProvider getPopupContainer={() => document.getElementById('popup-containers')}>
                                <TargetApp />
                            </ConfigProvider>
                        </MsalProvider>
                    </Provider>
                );
            })
            .catch((err) => {
                handleError(err);
            });
    }
});

const orgConsoleError = console.error;

console.error = (...args) => {
    const textValue = String(args[0]);

    if (textValue.includes(`Can't perform a React state update on a component that hasn't mounted yet`)) return;
    if (textValue.includes(`Support for defaultProps will be removed from function components in a future major release`)) return;
    if (textValue.includes(`findDOMNode is deprecated and will be removed in the next major release.`)) return;
    if (textValue.includes('Invalid DOM property')) return;
    if (textValue.includes('Consider using an empty string to clear the component or')) return;
    if (textValue.includes('Support for defaultProps will be removed from memo components in a future major release')) return;

    orgConsoleError(...args);
};

import { Alert, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isBeta } from '.';
import { ReactElement } from 'react';

type FallbackProps = {
    error: Error;
    componentStack: any;
    resetError: () => void;
};

export const showNewVersionModal = () => {
    Modal.warn({
        title: 'Warning',
        icon: <ExclamationCircleOutlined />,
        content: 'A new version of the site has been released. Please click OK to reload the page.',
        onOk: async () => {
            const cacheKeys = await caches.keys();

            cacheKeys.forEach(async (key) => {
                await caches.delete(key);
            });

            if (!isBeta()) {
                window.location.reload();
            }
        },
    });
};

export const FallbackComponent = ({ error, componentStack, resetError }: FallbackProps): ReactElement => {
    if (error.toString().includes('ChunkLoadError')) {
        resetError();
        showNewVersionModal();

        return <></>;
    }

    return <Alert type="error" message={error.toString()} />;
};

export const handleAppError = (error: Error) => {
    if (error.toString().includes('ChunkLoadError')) {
        showNewVersionModal();
    }
};

export const sorter = (a = {}, b = {}, property = '') => {
    return (a[property] ?? '').toString().localeCompare(b[property] ?? '');
};

export const sorterText = (a = '', b = '') => {
    return (a ?? '').localeCompare(b ?? '');
};

export const sortWithNullsAtBottom = (a, b, direction, property) => {
    const ascending = direction === 'ascend';

    if (a != null && b != null) {
        if (a[property] != null && b[property] != null) {
            if (a[property] > b[property]) {
                return 1;
            }

            if (a[property] < b[property]) {
                return -1;
            }
        }

        if (a[property]) {
            return ascending ? -1 : 1;
        }

        if (b[property]) {
            return ascending ? 1 : -1;
        }
    }

    return 0;
};

import { PayloadAction } from '@reduxjs/toolkit';
import { HospiceIntakeDto, HospiceIntakesClient } from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { CensusState } from './models';
import { censusSlice } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';

export const reducers = {
    setHospiceIntakes: (state: CensusState, action: PayloadAction<HospiceIntakeDto[]>) => {
        if (state.patient != null) {
            state.patientHospiceIntakes = action.payload;
        }
    },
};

export function fetchHospiceIntakes(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new HospiceIntakesClient(null, Axios);

        try {
            const response = await client.getAll(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setHospiceIntakes(response.result.entity));
            }
        } catch (error) {
            handleError(error, () => {});
        }
    };
}

export function addHospiceIntake(dto: HospiceIntakeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new HospiceIntakesClient(null, Axios);

        try {
            const response = await client.post(dto);

            if (response.result.succeeded) {
                await dispatch(fetchHospiceIntakes(dto.patientId));
            } else {
                handleError(response, () => {});
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => {});
        }

        return false;
    };
}

export function deleteHospiceIntake(dto: HospiceIntakeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new HospiceIntakesClient(null, Axios);

        try {
            const response = await client.delete(dto.id);

            if (response.result.succeeded) {
                await dispatch(fetchHospiceIntakes(dto.patientId));
            } else {
                handleError(response, () => {});
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => {});
        }

        return false;
    };
}

export const selectPatientHospiceIntakes = (state: RootState) => state.census.patientHospiceIntakes;

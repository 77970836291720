import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    MedicalSummaryDto,
    NotesClient,
    PatientIntakeDto,
    PatientIntakesClient,
    UnsignedStatsDto,
    UnacknowledgedQuickNotesBadgeDto,
    QuickNotesClient,
    FacilityQuickNotesDto,
    ReturnToHospitalBadgeDto,
    ReturnToHospitalClient,
    CensusBadgeRequestDto,
    CensusClient,
    CensusBadgeDto,
} from '@medone/medonehp-api-client';

import { AnyProvider, Role } from '../../../shared/common/auth/RoleAuth';
import { handleError } from '../../../shared/common/HandleErrors';
import { Axios } from '../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../shared/store';
import { initialState, PostAcuteState, quickNotesBadgesAdapter } from './models';
import { fetchTotalAbandoned } from './abandoned/slice';
import { isInRole } from '../../../shared/common/helpers';
import { PrintState } from './unsigned-notes/print';

export const postAcuteSlice = createSlice({
    name: 'postacute',
    initialState,
    reducers: {
        setError: (state: PostAcuteState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        setTotalUnsignedNotes: (state: PostAcuteState, action: PayloadAction<UnsignedStatsDto>) => {
            state.totalUnsignedNotes = action.payload;
        },

        setTotalUnacknowledgedQuickNotes: (state: PostAcuteState, action: PayloadAction<UnacknowledgedQuickNotesBadgeDto>) => {
            state.totalUnacknowledgedQuickNotes = action.payload;
        },

        setTotalDraftQuickNotes: (state: PostAcuteState, action: PayloadAction<UnacknowledgedQuickNotesBadgeDto>) => {
            state.totalDraftQuickNotes = action.payload.count;
        },

        setUnacknowledgedQuickNotes: (state: PostAcuteState, action: PayloadAction<FacilityQuickNotesDto[]>) => {
            state.unacknowledgedQuickNotes = action.payload;
        },

        setReturnToHospitalCount: (state: PostAcuteState, action: PayloadAction<ReturnToHospitalBadgeDto>) => {
            state.totalReturnToHospital = action.payload.count;
        },

        setQuickNotesBadges: (state: PostAcuteState, action: PayloadAction<CensusBadgeDto[]>) => {
            quickNotesBadgesAdapter.setMany(state.quickNotesBadges, action.payload);
        },

        setPrintState: (state: PostAcuteState, action: PayloadAction<PrintState>) => {
            state.printState = action.payload;
        },
    },
});

export const { setTotalUnsignedNotes, setPrintState } = postAcuteSlice.actions;

export function fetchUnsignedStats(providerId: string): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;
        const roles = [Role.POST_ACUTE_ADMIN, Role.CLINICAL_COORDINATOR, ...AnyProvider];

        // Since this call is from the menu, prevent attempting to call it if the user doesn't have access
        if (isInRole(permissions, roles)) {
            const client = new NotesClient(null, Axios);

            try {
                const response = await client.getUnsignedStats(providerId);

                if (response.result.succeeded) {
                    // Only update the count if this request was for the current provider
                    dispatch(postAcuteSlice.actions.setTotalUnsignedNotes(response.result.entity));
                } else {
                    handleError(response, () => dispatch(postAcuteSlice.actions.setError(null)));
                }

                dispatch(fetchTotalAbandoned());
            } catch (error) {
                handleError(error, () => dispatch(postAcuteSlice.actions.setError(error.toString())));
            }
        }
    };
}

export function postMedicalSummaryFields(dto: MedicalSummaryDto, bypassQueue: boolean): AppThunk<Promise<PatientIntakeDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            if (dto.noKnownAllergies == null) {
                dto.noKnownAllergies = false;
            }

            const response = bypassQueue ? await client.postMedicalSummaryFieldsByPassQueue(dto) : await client.postMedicalSummaryFields(dto);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(postAcuteSlice.actions.setError(null)));
            }

            return response.result.entity;
        } catch (error) {
            handleError(error);
        }

        return null;
    };
}

export function fetchUnacknowledgedCount(draftsOnly = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new QuickNotesClient(null, Axios);

        try {
            const response = await client.getUnacknowledgedCount(draftsOnly);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(postAcuteSlice.actions.setError(null)));
            } else {
                if (draftsOnly) {
                    dispatch(postAcuteSlice.actions.setTotalDraftQuickNotes(response.result.entity));
                } else {
                    dispatch(postAcuteSlice.actions.setTotalUnacknowledgedQuickNotes(response.result.entity));
                }
            }
        } catch (error) {
            handleError(error);
        }
    };
}

export function fetchUserQuickNotes(draftsOnly = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new QuickNotesClient(null, Axios);

        try {
            const response = await client.getAllByUser(draftsOnly);

            if (response.result.succeeded) {
                dispatch(postAcuteSlice.actions.setUnacknowledgedQuickNotes(response.result.entity));
            } else {
                handleError(response, () => dispatch(postAcuteSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error);
        }
    };
}

export function fetchReturnToHospitalCount(overrideToken?: string): AppThunk<Promise<UnacknowledgedQuickNotesBadgeDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { permissions } = getState().auth;
        const client = new ReturnToHospitalClient(null, Axios);
        const roles = [Role.POST_ACUTE_ADMIN, ...AnyProvider];

        if (isInRole(permissions, roles)) {
            try {
                const response = await client.getEligibleReturnToHospitalCount();

                if (!response.result.succeeded) {
                    handleError(response, () => dispatch(postAcuteSlice.actions.setError(null)));
                }

                dispatch(postAcuteSlice.actions.setReturnToHospitalCount(response.result.entity));

                return response.result.entity;
            } catch (error) {
                handleError(error);
            }
        }

        return null;
    };
}

export function fetchQuickNotesBadgeData(badgeDto: CensusBadgeRequestDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new CensusClient(null, Axios);

        try {
            const response = await client.getBadgeData(badgeDto);

            if (response.result.succeeded) {
                dispatch(postAcuteSlice.actions.setQuickNotesBadges(response.result.entity));
            } else {
                handleError(response, () => dispatch(postAcuteSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(postAcuteSlice.actions.setError(error.toString())));
        }
    };
}

export const selectError = (state: RootState) => state.postacute.errorMessage;
export const selectTotalUnsignedNotes = (state: RootState) => state.postacute.totalUnsignedNotes;
export const selectQuickNotesCount = (state: RootState) => state.postacute.totalUnacknowledgedQuickNotes;
export const selectUnacknowledgedQuickNotes = (state: RootState) => state.postacute.unacknowledgedQuickNotes;
export const selectTotalReturnToHospital = (state: RootState) => state.postacute.totalReturnToHospital;
export const selectTotalDraftQuickNotes = (state: RootState) => state.postacute.totalDraftQuickNotes;
export const selectPrintState = (state: RootState) => state.postacute.printState;
export const quickNotesBadgeSelectors = quickNotesBadgesAdapter.getSelectors();

export default postAcuteSlice.reducer;

import { configureStore, createListenerMiddleware, ThunkAction, AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

// Common
import authReducer, { fetchUserPermissions } from '../../shared/common/auth/slice';
import dataReducer, { dataSlice, fetchFeatureFlag, fetchLists } from '../../shared/common/data/slice';
import filesReducer from '../../shared/common/files/slice';

// Portal
import portalReducer from '../../post-acute/features//portal/slice';

// Post-Acute
import postAcuteReducer, { fetchUnsignedStats, fetchUnacknowledgedCount, fetchReturnToHospitalCount } from '../../post-acute/features/post-acute/slice';
import adminReducer from '../../post-acute/features/post-acute/admin/slice';
import censusReducer from '../../post-acute/features/post-acute/census/slice';
import routeBuilderReducer from '../../post-acute/features/post-acute/route-builder/slice';
import scheduleReducer from '../../post-acute/features/post-acute/schedule/slice';
import { fetchScheduleStats } from '../../post-acute/features/post-acute/schedule/slice.schedules';
import pccCensusReducer from '../../post-acute/features/post-acute/pcc-census/slice';
import unsignedNotesReducer from '../../post-acute/features/post-acute/unsigned-notes/slice';
import clinicalCoordinatorReducer, { fetchTotals } from '../../post-acute/features/post-acute/clinical-coordinator/slice';
import abandonedReducer from '../../post-acute/features/post-acute/abandoned/slice';
import newAdmitsReducer from '../../post-acute/features/post-acute/new-admits/slice';
import viewerReducer from '../../post-acute/features/post-acute/viewer/slice';
import returnToHospitalReducer from '../../post-acute/features/post-acute/return-to-hospital/slice';

// Users
import usersReducer from '../../post-acute/features//users/slice';

// Reporting
import reportingReducer from '../../post-acute/features/reporting/slice';

// Hub
import hubReducer, { initHubConnection, setOffline } from '../hubs/slice';

// Bed Board
import bedBoardReducer from '../../bedboard/features/bedboard/slice';
import bedBoardIntakeFormReducer from '../../bedboard/features/intake-form/slice';

import { adminSlice as bedboardAdminSlice } from '../../bedboard/features/bedboard/admin/slice';

import { fetchArrivingFroms } from '../../post-acute/features/post-acute/admin/slice.arriving-froms';
import { fetchFacilities } from '../../post-acute/features/post-acute/admin/slice.facilities';
import { fetchPatientTypes } from '../../post-acute/features/post-acute/admin/slice.patient-types';
import { fetchRegions } from '../../post-acute/features/post-acute/admin/slice.regions';

import { BuildInfo } from '../../Config';
import { isBedBoardIntakeForm, isBeta, isProduction } from '../common/helpers';
import { fetchHospiceCompanies } from '../../post-acute/features/post-acute/admin/slice.hospice-intake-companies';

// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware();

const showDevTools = () => {
    if (BuildInfo.IsBedBoard || isBeta() || !isProduction()) {
        // For now, always show devtools on the bedboard site
        // or dev/demo/beta sites
        return true;
    }

    // Only allow devtools in dev, not prod env
    return process.env.NODE_ENV !== 'production';
};

const logReduxEvents = ['census/setCurrentCensus', 'census/setNote', 'census/setPccPatient'];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
        if (logReduxEvents.includes(action.type)) {
            return action;
        }

        // Return null to not log the action to Sentry
        return null;
    },
});

export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            // Causes memory issues and performance issues with large datasets
            immutableCheck: false,
        })
            // Add the listener middleware to the store.
            // NOTE: Since this can receive actions with functions inside,
            // it should go before the serializability check middleware
            .prepend(listenerMiddleware.middleware),

    devTools: showDevTools(),

    enhancers: (getDefaultEnhancers) => {
        return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },

    reducer: {
        // Hub
        hub: hubReducer,

        // Common
        auth: authReducer,
        data: dataReducer,
        files: filesReducer,

        // Portal
        portal: portalReducer,

        // Post-Acute
        postacute: postAcuteReducer,
        admin: adminReducer,
        census: censusReducer,
        pccCensus: pccCensusReducer,
        routeBuilder: routeBuilderReducer,
        schedule: scheduleReducer,
        unsignedNotes: unsignedNotesReducer,
        clinicalCoordinator: clinicalCoordinatorReducer,
        abandoned: abandonedReducer,
        newAdmits: newAdmitsReducer,
        viewer: viewerReducer,
        returnToHospital: returnToHospitalReducer,

        // Users
        users: usersReducer,

        // Reporting
        reporting: reportingReducer,

        // Bed Board
        bedBoard: bedBoardReducer,
        bedBoardAdmin: bedboardAdminSlice.reducer,
        bedBoardIntakeForm: bedBoardIntakeFormReducer,
    },
});

let initData = false;

async function loadFeatureFlags() {
    await store.dispatch(fetchFeatureFlag('ShowReturnToHospital', true));
    await store.dispatch(fetchFeatureFlag('NewCensusRefresh', true));
    await store.dispatch(fetchFeatureFlag('UnsignedNotesFiltersEnabled', true));
    await store.dispatch(fetchFeatureFlag('ActiveVsChronicEnabled', true));

    setTimeout(loadFeatureFlags, 30000);
}

function initBedBoard() {
    async function asyncFunc() {
        if (!initData) {
            initData = true;

            try {
                store.dispatch(setOffline(false));

                await store.dispatch(fetchLists());

                await loadFeatureFlags();

                const pathParts = window.location.pathname.split('/');

                // Get the kiosk guid from the URL to assign as the group name
                // or get from the intake-form url
                const groupId = pathParts[2];

                // If this is a public intake-form kiosk, we need to send the hospital kiosk id to the hub
                // to act as the "userId" so we can send it messages
                await store.dispatch(initHubConnection(groupId != null ? groupId : null));
            } catch (ex) {
                Sentry.captureException(ex);

                store.dispatch(dataSlice.actions.setAppError('Failed to authenticate. Please verify you have access to this application.'));
            }
        }
    }

    asyncFunc();
}

function initBedBoardIntakeForm() {
    async function asyncFunc() {
        const { account } = store.getState().auth;

        // If auth times out, then force all data to re-init below
        if (account && !initData) {
            initData = true;

            try {
                await store.dispatch(fetchUserPermissions());
                await store.dispatch(fetchLists());
                await loadFeatureFlags();

                await store.dispatch(initHubConnection());
            } catch (ex) {
                Sentry.captureException(ex);

                store.dispatch(dataSlice.actions.setAppError('Failed to authenticate. Please verify you have access to this application.'));
            }
        }
    }

    asyncFunc();
}

function initPostAcute() {
    async function asyncFunc() {
        const { account } = store.getState().auth;

        // If auth times out, then force all data to re-init below

        if (account && !initData) {
            initData = true;

            try {
                await store.dispatch(fetchUserPermissions());
                await store.dispatch(fetchLists());
                await loadFeatureFlags();
                await store.dispatch(fetchRegions());
                await store.dispatch(fetchPatientTypes());
                await store.dispatch(fetchFacilities());
                await store.dispatch(fetchArrivingFroms());
                await store.dispatch(fetchUnsignedStats(account.localAccountId));
                await store.dispatch(fetchTotals());
                await store.dispatch(fetchScheduleStats(account.localAccountId));
                await store.dispatch(fetchReturnToHospitalCount());
                await store.dispatch(fetchHospiceCompanies());

                // Fetch quick note counts on page load, then after that let the background job perform updates on the interval defined
                await store.dispatch(fetchUnacknowledgedCount(false)); // Non-draft
                await store.dispatch(fetchUnacknowledgedCount(true)); // Draft

                await store.dispatch(initHubConnection());
            } catch (ex) {
                Sentry.captureException(ex);

                store.dispatch(dataSlice.actions.setAppError('Failed to authenticate. Please verify you have access to this application.'));
            }
        }
    }

    asyncFunc();
}

if (BuildInfo.IsBedBoard) {
    // Allow unauthenticated path to go through
    if (isBedBoardIntakeForm()) {
        store.subscribe(initBedBoard);
    } else {
        store.subscribe(initBedBoardIntakeForm);
    }
} else {
    store.subscribe(initPostAcute);
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<T = Promise<void>> = ThunkAction<T, RootState, void, AnyAction>;
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>;

import { handleError } from '../HandleErrors';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { DataListDto, DataClient, FeatureDto } from '@medone/medonehp-api-client';

import { BedBoardDataClient } from '@medone/medonehp-bedboard-client';

import { AppThunk, AppDispatch, RootState } from '../../store';
import { Axios } from '../http';
import { initialState, DataState } from './models';
import { BuildInfo } from '../../../Config';

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setAppError: (state: DataState, action: PayloadAction<string>) => {
            state.appError = action.payload;
            state.loading = false;
        },

        setItems: (state: DataState, action: PayloadAction<DataListDto>) => {
            state.items = action.payload;
            state.loading = false;
        },

        setFeatureFlags: (state: DataState, action: PayloadAction<FeatureDto[]>) => {
            state.featureFlags = action.payload;
            state.loading = false;
        },

        setFeatureFlag: (state: DataState, action: PayloadAction<FeatureDto>) => {
            const newFeatureFlags = [...state.featureFlags];
            const index = state.featureFlags.findIndex((x) => x.feature === action.payload.feature);

            if (index === -1) {
                newFeatureFlags.push(action.payload);
            } else {
                newFeatureFlags.splice(index, 1, action.payload);
            }

            state.featureFlags = newFeatureFlags;
        },
    },
});

let fetchDataCount = 0;

export function fetchLists(forceRefresh = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { data } = getState();

        // This sometimes could get called before the auth session is populated
        // so we catch the error and retry until it's ready

        if (fetchDataCount >= 10) {
            return;
        }

        fetchDataCount++;

        try {
            if (BuildInfo.IsBedBoard) {
                const client = new BedBoardDataClient(null, Axios);

                if (data.items == null || forceRefresh) {
                    const response = await client.getLists();

                    if (response.result.succeeded) {
                        dispatch(dataSlice.actions.setItems(response.result.entity));
                    }
                }
            } else {
                const client = new DataClient(null, Axios);

                if (data.items == null || forceRefresh) {
                    const response = await client.getLists();

                    if (response.result.succeeded) {
                        dispatch(dataSlice.actions.setItems(response.result.entity));
                    }
                }
            }
        } catch (ex) {
            setTimeout(() => {
                dispatch(fetchLists(forceRefresh));
            }, 2000);
        }
    };
}

export function fetchFeatureFlags(forceRefresh = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { data } = getState();

        try {
            if (BuildInfo.IsBedBoard) {
                const client = new BedBoardDataClient(null, Axios);

                if (data.featureFlags == null || forceRefresh) {
                    const response = await client.getFeatureFlags();

                    if (response.result.succeeded) {
                        dispatch(dataSlice.actions.setFeatureFlags(response.result.entity));
                    }
                }
            } else {
                const client = new DataClient(null, Axios);

                if (data.featureFlags == null || forceRefresh) {
                    const response = await client.getFeatureFlags();

                    if (response.result.succeeded) {
                        dispatch(dataSlice.actions.setFeatureFlags(response.result.entity));
                    }
                }
            }
        } catch (ex) {
            handleError(ex);
        }
    };
}

export function fetchFeatureFlag(feature: string, forceRefresh = false): AppThunk<Promise<FeatureDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { featureFlags } = getState().data;

        try {
            const existingValue = featureFlags.find((x) => x.feature === feature);

            if (!forceRefresh && existingValue != null) {
                return existingValue;
            }

            if (BuildInfo.IsBedBoard) {
                const client = new BedBoardDataClient(null, Axios);
                const response = await client.getFeatureFlag(feature);

                if (response.result.succeeded) {
                    dispatch(dataSlice.actions.setFeatureFlag(response.result.entity));

                    return response.result.entity;
                }
            } else {
                const client = new DataClient(null, Axios);
                const response = await client.getFeatureFlag(feature);

                if (response.result.succeeded) {
                    dispatch(dataSlice.actions.setFeatureFlag(response.result.entity));

                    return response.result.entity;
                }
            }
        } catch (ex) {
            handleError(ex);
        }

        return null;
    };
}

export const selectItems = (state: RootState) => state.data.items;
export const selectFeatureFlags = (state: RootState) => state.data.featureFlags;

export const selectStates = createSelector(selectItems, (data: DataListDto) => data?.states ?? []);
export const selectFacilityTypes = createSelector(selectItems, (data: DataListDto) => data?.facilityTypes ?? []);
export const selectGenders = createSelector(selectItems, (data: DataListDto) => data?.genders ?? []);
export const selectNoteTypes = createSelector(selectItems, (data: DataListDto) => data?.noteTypes ?? []);
export const selectPatientDispositions = createSelector(selectItems, (data: DataListDto) => data?.patientDispositions ?? []);
export const selectAlcoholIntakes = createSelector(selectItems, (data: DataListDto) => data?.alcoholIntakes ?? []);
export const selectTobaccoUses = createSelector(selectItems, (data: DataListDto) => data?.tobaccoUses ?? []);
export const selectIllicitDrugUses = createSelector(selectItems, (data: DataListDto) => data?.illicitDrugUses ?? []);
export const selectCodeStatuses = createSelector(selectItems, (data: DataListDto) => data?.codeStatuses ?? []);
export const selectTimeZones = createSelector(selectItems, (data: DataListDto) => data?.timeZones ?? []);
export const selectRequiredVisitTypes = createSelector(selectItems, (data: DataListDto) => data?.requiredVisitTypes ?? []);
export const selectVisitTypes = createSelector(selectItems, (data: DataListDto) => data?.visitTypes ?? []);
export const selectAdditionalRoles = createSelector(selectItems, (data: DataListDto) => data?.additionalRoles ?? []);
export const selectSpecialties = createSelector(selectItems, (data: DataListDto) => data?.specialties ?? []);
export const selectDocumentTypes = createSelector(selectItems, (data: DataListDto) => data?.documentTypes ?? []);
export const selectQuickNoteTypes = createSelector(selectItems, (data: DataListDto) => data?.quickNoteTypes ?? []);
export const selectExportTypes = createSelector(selectItems, (data: DataListDto) => data?.exportTypes ?? []);
export const selectBarriersToHealings = createSelector(selectItems, (data: DataListDto) => data?.barriersToHealings ?? []);
export const selectTreatmentDirectives = createSelector(selectItems, (data: DataListDto) => data?.treatmentDirectives ?? []);
export const selectOffLoadTreatments = createSelector(selectItems, (data: DataListDto) => data?.offLoadTreatments ?? []);
export const selectIncontinenceTreatments = createSelector(selectItems, (data: DataListDto) => data?.incontinenceTreatments ?? []);
export const selectEdemaTreatments = createSelector(selectItems, (data: DataListDto) => data?.edemaTreatments ?? []);
export const selectCarePlanDressingFrequencies = createSelector(selectItems, (data: DataListDto) => data?.carePlanDressingFrequencies ?? []);
export const selectCarePlanOtherProceduresAndTreatments = createSelector(selectItems, (data: DataListDto) => data?.carePlanOtherProceduresAndTreatments ?? []);
export const selectCarePlanProtectiveDressingOrTopicals = createSelector(selectItems, (data: DataListDto) => data?.carePlanProtectiveDressingOrTopicals ?? []);
export const selectCarePlanWoundBedDressings = createSelector(selectItems, (data: DataListDto) => data?.carePlanWoundBedDressings ?? []);
export const selectDebridementBleedingAmounts = createSelector(selectItems, (data: DataListDto) => data?.debridementBleedingAmounts ?? []);
export const selectDebridementHemostasisControlledBys = createSelector(selectItems, (data: DataListDto) => data?.debridementHemostasisControlledBys ?? []);
export const selectDebridementInstruments = createSelector(selectItems, (data: DataListDto) => data?.debridementInstruments ?? []);
export const selectDebridementLevels = createSelector(selectItems, (data: DataListDto) => data?.debridementLevels ?? []);
export const selectDebridementTissueLevels = createSelector(selectItems, (data: DataListDto) => data?.debridementTissueLevels ?? []);
export const selectDebridementTreatmentResponses = createSelector(selectItems, (data: DataListDto) => data?.debridementTreatmentResponses ?? []);
export const selectExamExudates = createSelector(selectItems, (data: DataListDto) => data?.examExudates ?? []);
export const selectExamExudateBleedingAmounts = createSelector(selectItems, (data: DataListDto) => data?.examExudatesBleedingAmounts ?? []);
export const selectExamPeriwounds = createSelector(selectItems, (data: DataListDto) => data?.examPeriwounds ?? []);
export const selectExamWoundBeds = createSelector(selectItems, (data: DataListDto) => data?.examWoundBeds ?? []);
export const selectNonPressureUlcers = createSelector(selectItems, (data: DataListDto) => data?.nonPressureUlcers ?? []);
export const selectNonPressureUlcerDepths = createSelector(selectItems, (data: DataListDto) => data?.nonPressureUlcerDepths ?? []);
export const selectPressureUlcers = createSelector(selectItems, (data: DataListDto) => data?.pressureUlcers ?? []);
export const selectWoundStatuses = createSelector(selectItems, (data: DataListDto) => data?.woundStatuses ?? []);
export const selectWoundTypes = createSelector(selectItems, (data: DataListDto) => data?.woundTypes ?? []);
export const selectWoundTypesOthers = createSelector(selectItems, (data: DataListDto) => data?.woundTypesOthers ?? []);
export const selectWoundHealPotentials = createSelector(selectItems, (data: DataListDto) => data?.woundHealPotentials ?? []);
export const selectWoundCareGoals = createSelector(selectItems, (data: DataListDto) => data?.woundCareGoals ?? []);
export const selectDepressionScreeningAnswers = createSelector(selectItems, (data: DataListDto) => data?.depressionScreeningAnswers ?? []);
export const selectBedBoardRequestTypes = createSelector(selectItems, (data: DataListDto) => data?.bedBoardRequestTypes ?? []);
export const selectAdvancedCareDirectiveFcodes = createSelector(selectItems, (data: DataListDto) => data?.advancedCareDirectiveFcodes ?? []);

export const selectVisionAssessmentTypes = createSelector(selectItems, (data: DataListDto) => data?.visionAssessmentTypes ?? []);
export const selectCognitiveAssessmentTypes = createSelector(selectItems, (data: DataListDto) => data?.cognitiveAssessmentTypes ?? []);
export const selectCognitiveImpairmentTypes = createSelector(selectItems, (data: DataListDto) => data?.cognitiveImpairmentTypes ?? []);
export const selectScreeningNotCompletedReasons = createSelector(selectItems, (data: DataListDto) => data?.screeningNotCompletedReasons ?? []);
export const selectHearingAssessmentTypes = createSelector(selectItems, (data: DataListDto) => data?.hearingAssessmentTypes ?? []);
export const selectHomeSafetyTypes = createSelector(selectItems, (data: DataListDto) => data?.homeSafetyTypes ?? []);
export const selectImmunizationStates = createSelector(selectItems, (data: DataListDto) => data?.immunizationStates ?? []);
export const selectAwvAssessmentTypes = createSelector(selectItems, (data: DataListDto) => data?.awvAssessmentTypes ?? []);

export const selectAppError = (state: RootState) => state.data?.appError;
export const selectReviewOfSystemsTemplate = (state: RootState) => state.data?.items?.reviewOfSystemsTemplate;
export const selectExamTemplate = (state: RootState) => state.data?.items?.examTemplate;
export const selectExamPmrTemplate = (state: RootState) => state.data?.items?.examPmrTemplate;
export const selectMiscellaneousPmrTemplate = (state: RootState) => state.data?.items?.miscellaneousPmrTemplate;
export const selectReviewOfSystemsPmrTemplate = (state: RootState) => state.data?.items?.reviewOfSystemsPmrTemplate;
export const selectProcedureModifiers = (state: RootState) => state.data?.items?.procedureModifiers;
export const selectHospiceEndOfServices = (state: RootState) => state.data?.items?.hospiceEndOfServices;

export default dataSlice.reducer;
